import dayjs from 'dayjs'
import isYesterday from 'dayjs/plugin/isYesterday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import isToday from 'dayjs/plugin/isToday'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import utc from 'dayjs/plugin/utc'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import isBetween from 'dayjs/plugin/isBetween'
import objectSupport from 'dayjs/plugin/objectSupport'
import toObject from 'dayjs/plugin/toObject'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import localeData from 'dayjs/plugin/localeData'
import timezone from './timezone'
import en from 'dayjs/locale/en'
import enCa from 'dayjs/locale/en-ca'
import frCa from 'dayjs/locale/fr-ca'
import es from 'dayjs/locale/es'
import esUs from 'dayjs/locale/es-us'
import {blank} from '@/components/common/Helpers'

dayjs.extend(isYesterday)
dayjs.extend(isTomorrow)
dayjs.extend(isToday)
dayjs.extend(advancedFormat)
dayjs.extend(customParseFormat)
dayjs.extend(isSameOrBefore)
dayjs.extend(utc)
dayjs.extend(localizedFormat)
dayjs.extend(isBetween)
dayjs.extend(objectSupport)
dayjs.extend(toObject)
dayjs.extend(isSameOrAfter)
dayjs.extend(localeData)
dayjs.extend(timezone)
dayjs.locale(en)
dayjs.locale(enCa)
dayjs.locale(frCa)
dayjs.locale(es)
dayjs.locale(esUs)
dayjs.locale('en')

/**
 * @param {string | number | Date | dayjs.Dayjs | null | undefined} t
 * @param {string | null} z
 * @param {boolean} resetTimezone
 * @return {dayjs.Dayjs}
 **/
export const date = (t, z = 'UTC', resetTimezone = false) => {
    if (blank(t)) {
        return dayjs().tz(z)
    }
    return dayjs(t).tz(z, resetTimezone)
}
