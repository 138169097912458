import { useEffect, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { toast } from 'react-toastify'
import moment from 'moment'
import { OrganizationStatus } from './enum/OrganizationStatus'
import { OrganizationType } from './enum/OrganizationType'
import currency from 'currency.js'
import { isEmpty, isNumber, isObject, isString } from 'lodash'
import { Str, Stringable } from 'stringable-laravel'
import I18n from '../../../../vendor/conedevelopment/i18n/resources/js/I18n'
import { date } from '@/components/common/Date'

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export function isSet(obj) {
    return obj && Object.keys(obj).length > 0
}

export function isCorporation(organization) {
    return organization.type == OrganizationType.Corporation.Value
}

export function getFilestackPath(assetType, organization = null) {
    return `/${import.meta.env.VITE_APP_ENV}${organization ? `/${organization.id}` : ``}/${assetType.StoragePath}/`
}

export function getQueryParam(searchQuery) {

    const params = new URLSearchParams()
    if (searchQuery) {
        params.append('q', searchQuery)
    }

    return params.toString()
}

export function getParams() {
    const params = {}

    if (useParams().page) {
        params.page = useParams().page
    }

    const query = new URLSearchParams(useLocation().search)

    if (query.get('q')) {
        params.search = query.get('q')
    }

    return params
}

export function now() {
    return new Date().toISOString().slice(0, 19).replace('T', ' ')
}

export function dateformat(date, format = 'll') {
    return moment(date).format(format)
}

export function dateformatutc(date, format = 'll') {
    return moment.utc(date).format(format);
}

export function dateformatOrTBD(date, format = 'll') {
    if (!moment(date).isValid()) return 'TBD'
    return dateformat(date, format)
}

export function dateformatrelative(date, format = 'll') {
    // if older than 24 hours, show the date
    if (moment().diff(date, 'hours') >= 24) return dateformat(date, format)

    return moment(date).fromNow()
}

export function timespan(start, end) {
    let startTime = start !== null ? moment(start) : null
    let endTime = end !== null ? moment(end) : null

    return `${startTime !== null ? startTime.format('h:mm a') : 'TBD'}${endTime !== null ? ('-' + endTime.format('h:mm a')) : ''}`
}

export function usePrevious(value) {
    const ref = useRef()
    useEffect(() => {
        ref.current = value
    })
    return ref.current
}

export const sendToast = (message, type = 'success') => {
    toast[type](message)
}

export const humanFileSize = (bytes, si = true, dp = 1) => {
    const thresh = si ? 1000 : 1024

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B'
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    let u = -1
    const r = 10 ** dp

    do {
        bytes /= thresh
        ++u
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

    return bytes.toFixed(dp) + ' ' + units[u]
}

export const getMonthName = (num) => {
    return new Date(moment().year(), num - 1).toLocaleString('default', { month: 'long' })
}

export const formatNumber = (num) => {
    const formatter = new Intl.NumberFormat(undefined)

    return formatter.format(num)
}
export const formatCurrency = (num, currencyString = 'USD', precision) => {
    const formatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: currencyString,
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
    })

    return formatter.format(num)
}

export const getAbsoluteHeight = (ele) => {
    const styles = window.getComputedStyle(ele)
    const margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom'])
    return Math.ceil(ele.offsetHeight + margin)
}

export const organizationCountries = {
    "US": {
        name: 'United States',
        country_id: 'US',
        territory_noun: 'State'
    },
    "CA": {
        name: 'Canada',
        country_id: 'CA',
        territory_noun: 'Province'
    }
}
export const organizationColorMap = new Map([
    ['Choose Status', { iconColor: 'bg-gray-400', textColor: 'text-gray-400', borderColor: 'border-gray-400' }],
    [OrganizationStatus.Committed.Value, {
        iconColor: 'bg-red-400',
        textColor: 'text-red-400',
        borderColor: 'border-red-400',
        color: 'red'
    }],
    [OrganizationStatus.ReadyForDesign.Value, {
        iconColor: 'bg-orange-400',
        textColor: 'text-orange-400',
        borderColor: 'border-orange-400',
        color: 'orange'
    }],
    [OrganizationStatus.InReview.Value, {
        iconColor: 'bg-purple-400',
        textColor: 'text-purple-400',
        borderColor: 'border-purple-400',
        color: 'purple'
    }],
    [OrganizationStatus.InDesign.Value, {
        iconColor: 'bg-blue-400',
        textColor: 'text-blue-400',
        borderColor: 'border-blue-400',
        color: 'blue'
    }],
    [OrganizationStatus.Staged.Value, {
        iconColor: 'bg-yellow-400',
        textColor: 'text-yellow-400',
        borderColor: 'border-yellow-400',
        color: 'yellow'
    }],
    [OrganizationStatus.Live.Value, {
        iconColor: 'bg-green-400',
        textColor: 'text-green-400',
        borderColor: 'border-green-400',
        color: 'green'
    }],
])


export const LegacyAnalytics = import.meta.env.VITE_LEGACY_GTM

export const sendLegacyGtm = (event, obj) => {
    if (isEmpty(LegacyAnalytics)) {
        return
    }

    const toPush = Object.assign(obj, { 'send_to': `${LegacyAnalytics}`, 'event': event, })

    window.dataLayer.push(toPush)
}

export const enumToArray = (enumObject) => {
    return Object.entries(enumObject).map(entry => {
        return {
            label: entry[0],
            value: entry[1].Value,
            ...entry[1]
        }
    })
}

export const randomString = (count = 10) => {
    return Math.random().toString(36).slice(count)
}

export const isNotEmpty = (value) => {
    return !isEmpty(value)
}

export const formatMoney = (amount, nonFloat = false, currencyString = 'USD', precision = 2) => {
    let a = parseFloat(amount)
    if (nonFloat) {
        a = parseFloat((a / 100).toFixed(2))
    }
    return a.toLocaleString('en-US', {
        style: 'currency',
        currency: currencyString,
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
    })
}

export const formatFlowerMoney = (amount, currencyString = 'USD') => {
    return formatMoney(amount, true, currencyString)
}

export const pluralize = (word, count) => {
    return count != 1 ? `${word}s` : word
}

export const showDateWithBlankString = (d, format = 'MMM D, YYYY') => {
    if (blank(d)) {
        return ''
    }
    return moment(d).format(format)
}

export const value = (val, ...props) => typeof val === 'function' ? val(...props) : val

/**
 *
 * @param {string|dayjs.Dayjs|null} calendarDate
 * @param {boolean} showYear
 * @param {string|null} tz
 * @param {Function|null} after
 * @return {JSX.Element}
 */
export const calendar = (calendarDate, showYear = false, tz = 'UTC', after) => {
    const day = isObject(calendarDate) ? calendarDate : date(calendarDate, tz)
    return (
        <div className="flex-0 text-center justify-center">
            {blank(calendarDate) ? (
                <div className="text-gray-400">—</div>
            ) : (
                <>
                    <div className="text-gray-400">{day.format('MMM')}</div>
                    <div className="flex justify-center">
                        <div
                            className="w-8 h-8 text-center justify-center bg-blue-500 rounded-full text-white p-1">{day.format('DD')}</div>
                    </div>
                    {(showYear || day.year() !== date(null, tz).year()) &&
                        <div className="text-gray-400">{day.year()}</div>
                    }
                    {after ? after(day) : ''}
                </>
            )}
        </div>
    )
}

export const is_numeric = (num) => !isNaN(parseFloat(num)) && isFinite(num)

export const blank = value => {

    if (
        value === undefined
        || value === null
    ) {
        return true
    }

    if (typeof value === 'string') {
        return value.trim().length === 0
    }

    if (is_numeric(value)) {
        return false
    }

    if (typeof value === 'boolean') {
        return false
    }

    if (typeof value === 'object') {
        return Object.keys(value).length === 0
    }

    if (Array.isArray(value)) {
        return value.length === 0
    }

    return false
}

export const filled = value => !blank(value)

export const empty = value => {

    if (typeof value === 'boolean') {
        return value === false
    }

    if (is_numeric(value)) {
        return value == 0
    }

    return blank(value)
}

export const not_empty = value => !empty(value)

export const getMethods = (obj) => {
    let properties = new Set()
    let currentObj = obj
    do {
        Object.getOwnPropertyNames(currentObj).map(item => properties.add(item))
    } while ((currentObj = Object.getPrototypeOf(currentObj)))
    return [...properties.keys()].filter(item => typeof obj[item] === 'function')
}

export const money = (amount, currencyString = 'USD', options = {}) => {

    const currencies = {
        'USD': {
            'symbol': '$',
            'precision': 2,
        },
        'CAD': {
            'symbol': 'CA$',
            'precision': 2,
        },
    }

    const moneyOptions = Object.assign(options, currencies[currencyString])

    return currency(amount, moneyOptions)
}

export const getSetting = (object, setting) => {
    if ((typeof object == 'object') && object.settings && object.settings[setting]) {
        return object.settings[setting]
    }
    return null
}

export const useDocumentTitle = title => {
    const [documentTitle, setDocumentTitle] = useState(title)
    useEffect(() => {
        document.title = documentTitle
    }, [documentTitle])

    return [documentTitle, setDocumentTitle]
}

export const formatPhone = (phone) => {
    if (!phone) {
        return ''
    }

    let number = ''
    if (isNumber(phone)) {
        number = phone.toString()
    } else if (isString(phone)) {
        number = phone
    } else {
        return ''
    }

    let numbers = number.replace(/[^0-9]/g, '')

    if (blank(numbers)) {
        return ''
    }

    if (numbers.length === 11) {
        numbers = numbers.substring(1)
    }

    if (numbers.length === 10) {
        return `(${numbers.substring(0, 3)}) ${numbers.substring(3, 6)}-${numbers.substring(6)}`
    }

    if (numbers.length === 7) {
        return `${numbers.substring(0, 3)}-${numbers.substring(3)}`
    }

    return numbers
}

/** @return {Stringable} **/
export const str = (value = null) => {
    if (blank(value)) {
        return Str.of('')
    }
    return Str.of(value)
}

export const t = new I18n

export const locale = window?.locale ?? 'en-US'

export const lang = str(locale).before('_').before('-').lower().toString()

export const flowerProductImageUrl = (product) => {

    const flowersImageUrl = import.meta.env.VITE_FLOWERS_CDN_URL

    const url = product?.image_url
    const urls = product?.images

    if (filled(url)) {
        if (str(url).startsWith('/')) {
            return `${flowersImageUrl}${url}`
        }
        return url
    }

    if (filled(urls)) {
        return `${flowersImageUrl}/${urls.replace(/\{(.*)\}/, '$1').split(',')[0]}`
    }

    return ''
}

export const domain = import.meta.env.VITE_APP_URL

export const appUrl = (url = '') => {
    const append = str(url).trim().whenNotEmpty((value) => {
        if (value.toString().charAt(0) !== '/') {
            return value.prepend('/')
        }
        return value
    })
    return str(domain).trim().append(append.toString()).toString()
}

export function capitalize(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
}
