// ONLY MODIFY THIS FILE BY RUNNING THE FOLLOWING COMMAND:
// php artisan react:enum

export const OrganizationType = {
    Corporation: {
        Name: 'Corporation',
        Value: 'corporation',
    },
    FuneralHome: {
        Name: 'Funeral Home',
        Value: 'funeral_home',
    },
    Cemetery: {
        Name: 'Cemetery',
        Value: 'cemetery',
    },
    Crematory: {
        Name: 'Crematory',
        Value: 'crematory',
    },
    CremationSociety: {
        Name: 'Cremation Society',
        Value: 'cremation_society',
    },
    Florist: {
        Name: 'Florist',
        Value: 'florist',
    },
    IntegrationPartner: {
        Name: 'Integration Partner',
        Value: 'integration_partner',
    },
}