// ONLY MODIFY THIS FILE BY RUNNING THE FOLLOWING COMMAND:
// php artisan react:enum

export const OrganizationStatus = {
    Committed: {
        Name: 'Committed',
        Value: 'Committed',
    },
    ReadyForDesign: {
        Name: 'Ready For Design',
        Value: 'Ready for Design',
    },
    InDesign: {
        Name: 'In Design',
        Value: 'In Design',
    },
    InReview: {
        Name: 'In Review',
        Value: 'In Review',
    },
    Staged: {
        Name: 'Staged',
        Value: 'Staged',
    },
    Live: {
        Name: 'Live',
        Value: 'Live',
    },
}